<template>
    <div>
        <v-row>
            <v-col v-for="(button, key) in buttons" :key="'btn-'+key"
            cols="12" sm="6" md="3">
                    <v-btn
                    block
                    :color="button.color"
                    dark
                    :plain="!button.active"
                    :outlined="!button.active"
                    @click="updateButtons(key)"
                    x-large>
                        {{ button.label }}
                        <v-icon v-if="!!button.icon" right>{{ button.icon }}</v-icon>
                    </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data: () => ({
        buttons: {
            red: { label: 'Wire has red', color: 'red', active: false },
            blue: { label: 'Wire has blue', color: 'blue', active: false },
            star: { label: 'Has star', icon: 'mdi-star' , color: 'warning', active: false },
            led: { label: 'Led on', icon: 'mdi-led-on', color: 'warning', active: false },
        }
    }),
    methods: {
        updateButtons(key) {
            this.buttons[key].active = !this.buttons[key].active;
            this.$emit('optionsUpdate', this.buttons);
        }
    }
}
</script>

<style>

</style>