<template>
    <v-container>
        <options-select @optionsUpdate="updateOptions"/>

        <v-divider class="my-3" />

        <sn-slider
        v-show="sn.important"
        v-model="sn.sliderValue"
        :alert="true"/>

        <batteries-slider
        v-show="batteries.important"
        v-model="batteries.sliderValue"
        :alert="true"/>

        <parallel-port
        v-show="pPort.important"
        v-model="pPort.value"
        :alert="true"/>

        <v-divider
        v-show="pPort.important || batteries.important || sn.important"
        class="my-3"/>

        <div class="text-center">
            <span class="text-h3" >{{ verdict }}</span>
        </div>
    </v-container>
</template>

<script>
import optionsSelect from '../components/modules/complicated-wires/options-select.vue'
import SnSlider from '../components/settings/sn-slider.vue';
import BatteriesSlider from '../components/settings/batteries-slider.vue';
import ParallelPort from '../components/settings/parallel-port.vue';

export default {
    components: { optionsSelect, SnSlider, BatteriesSlider, ParallelPort },
    data: () => ({
        sn: { sliderValue: 0, important: false },
        batteries: { sliderValue: 0, important: false },
        pPort: { value: 0, important: false },
        verdict: '???',
        options: null,
    }),
    methods: {
        updateOptions(options) {
            this.options = options;
            this.calculateResult();
        },
        calculateResult() {
            let field = 0b0000;
            if (this.options) {
                field |= this.options.red.active ? 0b1000 : 0;
                field |= this.options.blue.active ? 0b0100 : 0;
                field |= this.options.star.active ? 0b0010 : 0;
                field |= this.options.led.active ? 0b0001 : 0;
            }

            if (caseCut.includes(field)) {
                this.sn.important = false;
                this.pPort.important = false;
                this.batteries.important = false;
                this.verdict = 'CUT';
            }
            else if (caseDontCut.includes(field)) {
                this.sn.important = false;
                this.pPort.important = false;
                this.batteries.important = false;
                this.verdict = 'DO NOT CUT';
            }
            else if (caseSerial.includes(field)) {
                this.sn.important = true;
                this.pPort.important = false;
                this.batteries.important = false;
                const sn = this.sn.sliderValue;
                this.verdict = sn % 2 ? 'DO NOT CUT' : 'CUT' ;
            }
            else if (caseParallel.includes(field)) {
                this.sn.important = false;
                this.pPort.important = true;
                this.batteries.important = false;
                const pPort = this.pPort.value;
                this.verdict = pPort ? 'CUT' : 'DO NOT CUT';
            }
            else if (caseBatteries.includes(field)) {
                this.sn.important = false;
                this.pPort.important = false;
                this.batteries.important = true;
                const nBatt = this.batteries.sliderValue;
                this.verdict = nBatt >= 2 ? 'CUT' : 'DO NOT CUT';
            }
            else {
                this.verdict = '???';
            }
        }
    },
    created() {
        this.calculateResult();
    },
    updated() {
        this.calculateResult();
    }
}

const caseCut = [0b0000, 0b0010, 0b1010];
const caseDontCut = [0b0001, 0b0110, 0b1111];
const caseSerial = [0b0100, 0b1000, 0b1100, 0b1101];
const caseParallel = [0b0101, 0b0111, 0b1110];
const caseBatteries = [0b0011, 0b1001, 0b1011];
</script>

<style>

</style>